<header class="header">
  <h1>Robert Ferentz</h1>
  <nav>
    <ul class="menu">
      <li><a href="/">Home</a></li>
      <li><a href="/examples">Examples</a></li>
      <li><a href="/about">About Me</a></li>
      <li><a href="/resume">Resume</a></li>      
    </ul>
  </nav>
</header>

<style scoped>
  .header {
    display: grid;
    width: 100%;    
    grid-template-rows: 50% 50%;
    place-items: center;
  }
  .menu {
    display: flex;
    flex-direction: row;
    gap: 5em;
    padding: 1em 6em;        
  }
    
</style>