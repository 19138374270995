<script>	
	import Header from './shared/Header.svelte';
	import Footer from './shared/Footer.svelte';
	import {Router} from 'svelte-router-spa';
	import routes from './routes.js';
	// export let url = "";
</script>
<svelte:head>
	<title>Robert Ferentz Portfolio</title>
	<meta keywords="Robert Ferentz, web developer, developer, portfolio, full-stack" />
	<meta author="Robert Ferentz <robiferentz@gmail.com>" />
	<meta description="The portfolio web site of Robert Ferentz" />
</svelte:head>
<div class="container">
	<Header />
	<main>
		<Router {routes}/>	
	</main>	
	<Footer />
</div>

<style scoped>
		.container {
			position: relative;
			width:100%;
			height: 100%;
			display: grid;
			grid-template-columns: 100%;
			grid-template-rows: 8rem auto 6rem;			
			place-items: center;
		}
		main{
			width:100%;
			height: 100%;
			position: relative;
		}	
</style>