import { readable } from 'svelte/store'

const cv = readable({
  summary: 'Simplicity-oriented but curiosity-motivated software developer with 16+ years of experience building web applications (plus some other things) on a wide variety of tech stacks and platforms and an even wider variety of target fields. I enjoy troubleshooting challenges, and love new concepts and ideas in tech. I enjoy trying new tools and methods, but I try to be as pragmatic as possible when it comes to actual work.',
  skills: [
    {
      category: 'Programming languages',
      text: 'JavaScript, TypeScript, Java, C#, Java and Rust'
    },
    {
      category: 'Web Development',
      text: 'HTML, CSS, React, Vue, NodeJS, Deno'
    },
    {
      category: 'Mobile',
      text: 'Android (Java). Ionic Framework.'
    },
    {
      category: 'Cloud',
      text: 'GCP (Firebase)'
    },
    {
      category: 'Miscellaneous',
      text: 'Technical writing. English->Hebrew->English translation'
    },
  ],
  history: [
    {
      company: 'August 2021 - August 2022: Intuition Robotics- Full stack / Android developer.',
      bullets: [
        'Android development in Java.',
        'Web Front-End development in React.',
        'Back-end development in NodeJS (using Firebase platform).',
        'Working with local and remote development squads.',
        'Developed logic and experiences on a table-top robot, designed to help the elderly (ElliQ).',
      ],
    },
    {
      company: 'April 2020 - June 2021: SeamlessDocs (now GovOS) - Front-End / Full stack developer.',
      bullets: [
        'Front-End development in React and AngularJs(legacy).',
        'Back-end development in Scala, PHP(legacy) and NodeJS.',
        'Working with local and remote development squads.',
        'Developed an internal framework for complex forms for government use.',
      ],
    },
    {
      company: 'JULY 2013 - March 2020: Tikal Knowledge - Front-End / Full stack developer. ',
      bullets: [
        'Front-End development in multiple projects, using Javascript, including AngularJS, Angular, React, Vue etc.',
        'Back-end and tool development using NodeJS and other technologies.',
        'Working with development teams of all make-ups and types at every different project/client.',
        'Mentoring new and old developers.',
      ],
    },
    {
      company: '2011 – 2013: Clarizen - Front-End developer. (C#, .NET, JQuery)',
    },
    {
      company: '2009 – 2011: Central Park Media (BroadwayBox.com) - Web developer. (C#, .NET, JQuery)',
    },
    {
      company: '2008 – 2009: Universal-Ad - Web developer. (C#, .NET)',
    },
    {
      company: '2006 – 2008: SkillPoker LTD -Web programmer. (C#, .NET)',
    },
  ],
  education: [
    '2002: Completed a programming course in C, C++, VC++ at Start-Tech non-academic college. (For MCSD Cert.)',
    '2001: Completed a Webmaster course at Start-Tech non-academic college.',
  ],
})

export { cv }
