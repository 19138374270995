import Home from './pages/Home.svelte'
import Examples from './pages/Examples.svelte'
import Resume from './pages/Resume.svelte'
import About from './pages/About.svelte'

const routes = [
  { name: '/', component: Home },
  { name: 'about', component: About },
  { name: 'resume', component: Resume },
  { name: 'examples', component: Examples },
]

export default routes
