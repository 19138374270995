<div class="">
  <details>
    <summary>A little bit about me</summary>
    <ul>
      <li>A (crappy) gamer, dad, dog-dad, husband and programmer. Not necessarily in that order.</li>
      <li>I live in Rishon-Lezion, Israel.</li>
      <li>I hate the heat, love cold and winter, so in Israel that means an AC unit on me at all times.</li>
      <li>I love science, science-fiction & fantasy in all my pass-times of reading, watching TV or gaming.</li>
      <li>I also enjoy learning about new tech, both in the programming field and outside of it.</li>
      <li>I do not drink coffee, but do drink chocolate-milk.</li>
    </ul>
  </details>
</div>