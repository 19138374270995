<script>
  import { cv } from '../stores/cv'

  function itemClicked(event) {
    console.log(event.target.innerHTML)
  }  
  // function fib(num) {
  //   let ult = 1,
  //     penult = 0
  //   if (num < 2) {
  //     return num
  //   }
  //   for (let i = 0; i < num - 1; i++) {
  //     let tmp = penult
  //     penult = ult
  //     ult += tmp
  //   }
  //   return ult
  // }

  // function fibRec(num) {
  //   if (num < 2) {
  //     return num
  //   }
  //   return fibRec(num - 1) + fibRec(num - 2)
  // }

  // console.time('fib')
  // console.log(fib(30))
  // console.timeEnd('fib')
  // console.time('fibRec')
  // console.log(fibRec(30))
  // console.timeEnd('fibRec')
</script>

<div class="resume-main">
  <p class="summary">
    {$cv.summary}
  </p>
  <br />
  <h3>Skills</h3>
  <br />
  <ul>
    {#each $cv.skills as item}
      <li>{item.category} : {item.text}</li>
    {/each}
  </ul>
  <br />
  <h3>Work Experience</h3>
  <br />
  <ul class="item-holder">
    {#each $cv.history as item}
      <li>
        {item.company}
        <ul>
          {#if item.bullets}
            {#each item.bullets as bullet}
              <li on:click={itemClicked}>{bullet}</li>
            {:else}
              <span />
            {/each}
          {/if}
        </ul>
      </li>
    {/each}
  </ul>
  <br />
  <h3>Education</h3>
  <br />
  <ul>
    {#each $cv.education as item}
      <li>{item}</li>
    {:else}
      <span />
    {/each}
  </ul>
</div>

<style scoped>
  .resume-main {
    margin: auto;
    width: 60%;
  }
  .item-holder {
    width: 100%;
    list-style-type: '->  ';
    display: flex;
    flex-direction: column;
    gap: 2ch;
  }
  
  .summary {
    color: transparent;
    background: linear-gradient(to bottom right, #659999, #f4791f);
    background-clip: text;
    -webkit-background-clip: text;
  }
</style>
